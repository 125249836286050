<template>
  <div class="elv-counterparty-named-page-container">
    <ContactScreening tableType="Contact">
      <template #button>
        <el-button class="elv-counterparty-header-right__export" link @click="onImportCounterparty">
          <SvgIcon name="Import-document" width="16" height="16" />
          {{ t('button.importCounterparty') }}
        </el-button>
        <elv-export-button
          :params="{
            type: 'COUNTERPARTY',
            extra: {}
          }"
          textColor="#636b75"
          :noPermission="
            ['MEMBER', ''].includes(currentEntityPermission?.role) && !currentEntityPermission?.counterparty?.create
          "
        />
        <elv-button
          style="margin-left: 16px"
          height="32"
          width="120"
          round
          plain
          type="primary"
          @click="onAddCounterparty"
        >
          <SvgIcon name="source-add" width="16" height="16" />{{ t('button.addNew') }}</elv-button
        >
      </template>
    </ContactScreening>

    <ul
      v-if="entityStore.entityDetail?.progress?.counterpartyCsvUploadList?.length"
      class="elv-counterparty-upload-status-list"
    >
      <li
        v-for="(item, index) in entityStore.entityDetail?.progress?.counterpartyCsvUploadList"
        :key="index"
        class="elv-counterparty-upload-status-item"
      >
        <div class="elv-counterparty-upload-status-item__state">
          <template v-if="['FINISHED', 'FAILED', 'FAIL'].includes(item.status)">
            <template v-if="item.status === 'FINISHED'">
              <SvgIcon class="success" name="checkmark-filled" width="18" height="18" fill="#94C300" />
              {{ t('title.fileImportCompleted', { name: item?.entityFile?.name.split('.csv')?.[0] }) }}
            </template>
            <template v-else>
              <SvgIcon class="success" name="sources-sync-error" width="18" height="18" fill="#94C300" />
              {{ t('title.fileImportFailed', { name: item?.entityFile?.name.split('.csv')?.[0] }) }}
            </template>
          </template>
          <template v-else>
            <img src="@/assets/img/reports/sources-sync-loading.png" alt="loading" />
            {{ t('title.fileIsImporting', { name: item?.entityFile?.name.split('.csv')?.[0] }) }}
          </template>
        </div>
        <template v-if="['FINISHED', 'FAILED', 'FAIL'].includes(item.status)">
          <p class="elv-counterparty-upload-status-item__success">
            {{ t('common.successfully') }}: {{ formatNumber(item.successCount) }}
            {{ item.successCount > 1 ? t('common.items') : t('common.item') }}
          </p>
          <div v-if="item.failCount" class="elv-counterparty-upload-status-item__failure">
            {{ t('common.failure') }}:&nbsp;
            <p>{{ formatNumber(item.failCount) }}</p>
            &nbsp;{{ item.failCount > 1 ? t('common.items') : t('common.item') }}
            <span v-if="item.failFileDownloadUrl" @click="onDownloadFailFile(item.failFileDownloadUrl)">{{
              t('button.clickDownloadAndReEdit')
            }}</span>
          </div>
        </template>
        <div
          v-if="['FINISHED', 'FAILED', 'FAIL'].includes(item.status)"
          v-loading="closeLoading && currentFileData.counterpartyCsvUploadId === item.counterpartyCsvUploadId"
          class="elv-counterparty-upload-status-item__close"
        >
          <SvgIcon name="common-close" width="18" height="18" fill="#838D95" @click="onCloseUploadStatus(item)" />
        </div>
      </li>
    </ul>

    <ContactNamedList
      ref="contactNamedListRef"
      :contactsParams="counterpartListParams"
      :tableHeight="tableHeight"
      :tableData="accountStore.counterpartList"
      :tableLoading="loading"
      @resetList="resetList"
      @onChangePage="onChangePage"
      @onAddCounterparty="onAddCounterparty"
      @onChangePageSize="onChangePageSize"
      @onEditCounterparty="onEditCounterparty"
    />

    <CounterpartyDialog
      ref="counterpartyDialogRef"
      :model="counterpartyDialogMode"
      :currentData="currentData"
      @resetList="resetList"
    />

    <UploadCSVDialog
      ref="uploadCSVDialogRef"
      :title="t('button.addCounterparty')"
      templateSlug="general-counterparty"
      templateUrl="file/csv/Elven%20Counterparty%20Template.csv"
      :noPermission="
        ['MEMBER', ''].includes(currentEntityPermission?.role) && !currentEntityPermission?.counterparty?.create
      "
      @onSaveImport="onSaveImport"
      @onResetList="resetList"
    />
  </div>
</template>

<script setup lang="ts">
import { useI18n } from 'vue-i18n'
import { ElMessage } from 'element-plus'
import { formatNumber } from '@/lib/utils'
import AccountsApi from '@/api/AccountsApi'
import { downloadFile } from '@/lib/download'
import { useEntityStore } from '@/stores/modules/entity'
import ContactScreening from '../components/Screening.vue'
import { useAccountStore } from '@/stores/modules/accounts'
import ContactNamedList from './components/ContactNamedList.vue'
import ElvExportButton from '@/components/Base/ElvExportButton.vue'
import CounterpartyDialog from '../../components/CounterpartyDialog.vue'
import UploadCSVDialog from '@/pages/Financials/Project/components/UploadCSVDialog.vue'

const { t } = useI18n()
const route = useRoute()
const entityStore = useEntityStore()
const accountStore = useAccountStore()

const loading = ref(false)
const closeLoading = ref(false)
const uploadCSVDialogRef = useTemplateRef('uploadCSVDialogRef')
const contactNamedListRef = useTemplateRef('contactNamedListRef')
const counterpartyDialogRef = useTemplateRef('counterpartyDialogRef')
const currentData: any = ref({})
const currentFileData = ref<any>({})
const counterpartyDialogMode = ref('add')
const counterpartListParams: any = ref({
  page: 1,
  limit: 20
})

const entityId = computed(() => {
  return route.params?.entityId as string
})

const currentEntityPermission = computed(() => {
  return entityStore.entityPermission()
})

const tableHeight = computed(() => {
  return `calc(100% - 52px - ${
    entityStore.entityDetail?.progress?.counterpartyCsvUploadList?.length
      ? (entityStore.entityDetail?.progress?.counterpartyCsvUploadList?.length ?? 0) * 50 + 4
      : 0
  }px)`
})

const onDownloadFailFile = (failFileDownloadUrl: string) => {
  downloadFile(failFileDownloadUrl)
}

const onImportCounterparty = () => {
  if (
    ['MEMBER', ''].includes(currentEntityPermission.value?.role) &&
    !currentEntityPermission.value?.counterparty?.create
  ) {
    ElMessage.warning(t('message.noPermission'))
    return
  }
  uploadCSVDialogRef.value?.onCheckUploadCSVDialog()
}

const onCloseUploadStatus = async (row: any) => {
  try {
    currentFileData.value = row
    closeLoading.value = true
    await AccountsApi.closeCounterpartyCsvUploadStatus(entityId.value, row.counterpartyCsvUploadId)
    ElMessage.success(t('message.success'))
    entityStore.fetchEntityDetail(entityId.value)
  } catch (error: any) {
    console.log(error)
    ElMessage.error(error.message)
  } finally {
    closeLoading.value = false
  }
}

const onSaveImport = async (params: any) => {
  try {
    const data = {
      entityFileId: params.entityFileId
    }
    const res = await AccountsApi.importCounterparty(entityId.value, data)
    uploadCSVDialogRef.value?.onSuccessImport()
    return Promise.resolve(res)
  } catch (error: any) {
    ElMessage.error(error.message)
    return Promise.reject(error)
  } finally {
    uploadCSVDialogRef.value?.onFinallyImport()
  }
}

const onAddCounterparty = () => {
  if (
    ['MEMBER', ''].includes(currentEntityPermission.value?.role) &&
    !currentEntityPermission.value?.counterparty?.create
  ) {
    ElMessage.warning(t('message.noPermission'))
    return
  }
  currentData.value = {}
  counterpartyDialogMode.value = 'add'
  counterpartyDialogRef.value?.onCheckCounterpartyDialog()
}

const onEditCounterparty = (row: any) => {
  if (
    ['MEMBER', ''].includes(currentEntityPermission.value?.role) &&
    !currentEntityPermission.value?.counterparty?.update
  ) {
    ElMessage.warning(t('message.noPermission'))
    return
  }
  currentData.value = row
  counterpartyDialogMode.value = 'edit'
  counterpartyDialogRef.value?.onCheckCounterpartyDialog()
}

const getCounterpartTableData = async () => {
  try {
    loading.value = true
    await accountStore.fetchCounterpartList(entityId.value, counterpartListParams.value)
    contactNamedListRef.value?.ref?.setScrollTop(0)
  } catch (error) {
    console.log(error)
  } finally {
    loading.value = false
  }
}

const resetList = () => {
  counterpartListParams.value.limit = 20
  counterpartListParams.value.page = 1
  getCounterpartTableData()
}

const onChangePageSize = (limit: number) => {
  counterpartListParams.value.limit = limit
  counterpartListParams.value.page = 1
  getCounterpartTableData()
}

const onChangePage = (page: number) => {
  counterpartListParams.value.page = page
  getCounterpartTableData()
}

watch(
  route,
  async (newValue) => {
    if (newValue.name === 'entity-accounts-contacts-named') {
      accountStore.counterpartList = { total: 0, list: [] }
      getCounterpartTableData()
    }
  },
  { immediate: true, deep: true }
)
</script>

<style lang="scss">
.elv-counterparty-header-right__export.el-button {
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0px;
  border-radius: 2px;
  font-family: 'Plus Jakarta Sans';
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  color: #636b75;
  cursor: pointer;
  margin-left: 8px;

  svg {
    fill: #838d95;
    margin-right: 8px;
  }

  &:hover {
    span {
      color: #1343bf;
      background-color: #fff;
    }

    svg {
      fill: #1343bf;
    }
  }
}

.elv-counterparty-upload-status-list {
  width: 100%;
  box-sizing: border-box;
  padding: 0 30px 0 20px;
  display: inline-flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 12px;
  margin-bottom: 16px;

  .elv-counterparty-upload-status-item {
    width: 100%;
    height: 38px;
    display: flex;
    box-sizing: border-box;
    padding: 10px 8px;
    align-items: center;
    border-radius: 2px;
    border: 1px solid #abc0f5;
    background: rgba(229, 237, 255, 0.2);
    gap: 32px;
    position: relative;

    .elv-counterparty-upload-status-item__state {
      display: flex;
      align-items: center;
      gap: 8px;
      color: #1e2024;
      font-family: 'Plus Jakarta Sans';
      font-size: 13px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;

      img {
        width: 18px;
        height: 18px;
        display: block;
        animation: loading-rotate 2s linear infinite;
      }
    }

    .elv-counterparty-upload-status-item__success {
      color: #1e2024;
      font-family: 'Plus Jakarta Sans';
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }

    .elv-counterparty-upload-status-item__failure {
      display: flex;
      align-items: center;
      color: #1e2024;
      font-family: 'Plus Jakarta Sans';
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;

      p {
        color: #df2200;
      }

      span {
        color: #1753eb;
        font-size: 13px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        text-decoration-line: underline;
        text-transform: lowercase;
        margin-left: 8px;
        cursor: pointer;
      }
    }

    .elv-counterparty-upload-status-item__close {
      position: absolute;
      right: 8px;
      cursor: pointer;

      &:hover svg {
        fill: #1343bf;
      }

      .el-loading-mask {
        .circular {
          width: 28px;
          height: 28px;
          position: relative;
          top: 6px;
          left: -6px;
        }
      }
    }
  }
}

.elv-counterparty-named-page-container {
  width: 100%;
  height: calc(100% - 90px);
  position: relative;
}
</style>
